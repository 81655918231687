<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover">
        <div class="page-inner" id="main_page">
          <div class="va-card mb-4">
            <div class="dash_card_body pad-lr-0 ov-h">
              <div class="header_section d-block padd-30 pt-0 pb-0">
                <div class="w-100 t-wrap">
                  <h4 class="menu_header">Dashboard</h4>
                </div>
              </div>
              
              <div class="wrap-dash bd-top">
                <div class="row">
                  <div class="col-md-8 bd-right pad-lr-0">
                    <div class="pl-3r pr-2r pbt-1r bd-b">
                      <div class="content_wrapper_box summary_card graph-summary">
                        <div class="wrapper_header p-sm">
                          <div class="header_section wrapper_search">
                            <div class="row">
                              <div class="col-md-3">
                                <Multiselect class="dash_summary_form" @click="filterSalesSummary()" v-model="location"
                                  :close-on-select="true" :options="strsoptions" :custom-label="name" :searchable="true"
                                  :placeholder="'Stores ('+strsoptions.length +')'" label="name" track-by="name">
                                </Multiselect>
                              </div>
                              <div class="col-md-5">
                                <!-- <Datepicker class="dash_summary_form" v-model="dateRange" utc autoApply :range="true"
                                  :format="RangeCustomFormatter" @blur="filterSalesSummary()"></Datepicker> -->

                                <Datepicker  class="dash_summary_form"  v-model="dateRange" range :presetRanges="presetRanges" utc autoApply  :format="RangeCustomFormatter" @blur="filterSalesSummary()">
                                  <template #yearly="{ label, range, presetDateRange }">
                                    <span @click="presetDateRange(range)">{{ label }}</span>
                                  </template>
                                </Datepicker>
                              </div>
                            </div>
                          </div>
                          <div class="header_section wrapper_search">
                            <div class="dash_header_text"><h2>{{summaryTitle}}</h2></div>
                            <div class="more-link">
                              <button :class="'btn btn-sm mr-2 '+ salesBtn" @click="getSalesData('sales')">Sales</button>
                              <button :class="'btn btn-sm' + ordersBtn" @click="getSalesData('orders')">Orders</button>
                            </div>
                          </div>
                          <div class="summary_desc col-md-10">
                            <div class="row">
                              <div class="col-6 current-p">
                                <div class="summary_header">Total {{summaryTitle}}</div>
                                <div class="amnt">{{formatPrice(totalOrderSales)}}</div>
                                <div class="current_period"></div><span>Current Period ({{currentPeriodTime}})</span>
                              </div>
                              <div class="col-6 prev-p">
                                <div class="summary_header">Total {{summaryTitle}}</div>
                                <div class="amnt">{{formatPrice(totalPreviousOrderSales)}} &nbsp; &nbsp;
                                  <span v-if="change !='nochange'"
                                    :class="[{'increase_change':change == 'increase'},{'decrease_change': change == 'decrease'}]">
                                    <i :class="[{'fa fa-arrow-up':change == 'increase'},{'fa fa-arrow-down':change == 'decrease'}]"
                                      style="font-size:12px"></i> {{percentangeChange}}%
                                  </span>
  
                                </div>
                                <div class="previos_period"></div> <span>Previous Period ({{ previousPeriodTime }})</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="chart-container" class="row">
                          <canvas id="summary-chart" class="col-12"></canvas>
                        </div>
                      </div> <!--Graph-->
                    </div><!-- End Graph-->
                    <div class="pl-3r pr-2r pbt-1r bd-b">
                      <div class="content_wrapper_box pop">
                        <div class="wrapper_header p-sm">
                          <div class="header_section wrapper_search ">
                            <div class="row">
                              <div class="col-md-4 mb-sm-1">
                                <Multiselect class="dash_summary_form" v-model="itemStore" :close-on-select="true"
                                  :options="strsoptions" :custom-label="name" :searchable="true"
                                  :placeholder="'Stores ('+strsoptions.length +')'" label="name" track-by="name"
                                  @click="searchReport(itemsDataRange,'productsales')"></Multiselect>
                              </div>
                              <div class="col-md-5 mb-sm-1">
                                <!-- <Datepicker class="dash_summary_form" v-model="itemsDataRange" utc autoApply :range="true"
                                  :format="RangeCustomFormatter" @blur="searchReport(itemsDataRange,'productsales')">
                                </Datepicker> -->

                                <Datepicker  class="dash_summary_form"  v-model="itemsDataRange" range :presetRanges="presetRanges" utc autoApply  :format="RangeCustomFormatter" 
                                  @blur="searchReport(itemsDataRange,'productsales')">
                                  <template #yearly="{ label, range, presetDateRange }">
                                    <span @click="presetDateRange(range)">{{ label }}</span>
                                  </template>
                                </Datepicker>


                              </div>
                              <div class="col-md-3">
                                <button :class="'btn btn-sm w-100 '+catBtnType" @click="exportOrder()"><span v-if="spinner"><i
                                  class="fa fa-spinner fa-spin"></i></span>Download</button>
                              </div>
                            </div>
                            
  
                          </div>
                          <div class="header_section">
                            <div class="dash_header_text"><h2>Popular Items</h2></div>
                            <div class="more-link">
                              <a href="#" class="orange_link"
                                @click="redirectToDetailPage('report','salesByProduct')">View</a>
                            </div>
                          </div>
  
                        </div>
                        <div class="wrapper_body">
                          <div class="table_section">
                            <table class="table  table-hover">
                              <thead>
                                <th  style="padding-left: 9% !important;" width="40%">Product</th>
                                <th>Orders</th>
                                <th>Order Sales</th>
                                <th>Items Sold</th>
                                <th>Item Sales</th>
                                
                              </thead>
                              <tbody v-if="productSales.length > 0">
                                <tr v-for="productSale in productSales" :key="productSale.name">
                                  <td>
                                    <span v-if="productSale.image">
                                      <img  style="border-radius:3px;vertical-align: middle;" width="32" height="32" :src="productSale.image" alt=""> &nbsp;
                                    </span>
                                    <span v-else>
                                      <img style="border-radius:3px;vertical-align: middle;" width="32" height="32" src="../../../../public/img/no_images.jpg" alt="">
                                      &nbsp;
                                    </span>
                                    <span class="title_text">
                                      {{productSale.name}}
                                    </span>
  
                                  </td>
                                  <td>{{productSale.total_order}}</td>
                                  <td>{{formatPrice(productSale.total_amount)}}</td>
                                  <td>{{ productSale.total_qty }}</td>
                                  <td>{{ formatPrice(productSale.item_total) }}</td>
                                 
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="5" style="text-align:center;">No data</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div><!-- popular items-->
                      
                    </div><!-- End popular items-->
                    <div class="pl-3r pr-2r">
                      <div class="row">
                        <div class="col-md-6 bd-right bd-sm-right">
                          <div class="section_content pad-lr-0 pop">
                            <div class="header_section wrapper_search">
                              <div class="dash_header_text">
                                <h2>Current Offers</h2></div>
                              <div class="more-link">
                                <a href="#" @click="redirectToDetailPage('report','offers')" class="orange_link">View Offers</a>
                              </div>
                            </div>
                            <div>
                              <div class="table_section">
                                <table class="table  table-hover">
                                  <thead>
                                    <th width="30%">Offer</th>
                                    <th>Orders</th>
                                    <th>Sales</th>
                                    <th>Expiry Date</th>
                                  </thead>
                                  <tbody v-if="offerSummary.length > 0">
                                    <tr v-for="(offer,index) in offerSummary" :key="index">
                                      <td>
                                        <span class="title_text">
                                          {{offer.offer_name}}
                                        </span>
                                      </td>
                                      <td>{{formatPrice(offer.total_order)}}</td>
                                      <td>{{formatPrice(offer.total_amount)}}</td>
                                      <td>{{formatDate(offer.end_date)}}</td>
                                    </tr>
                                  </tbody>
                                  <tbody v-else>
                                    <tr>
                                      <td colspan="4" style="text-align:center;">No data</td>
                                    </tr>
                                  </tbody>
  
                                </table>
                              </div>
                            </div>
                          </div></div>
                        <div class="col-md-6">
                          <div class="section_content pad-lr-0 pt-sm-0 ">
                            <div class="dash_header_text"><h2>Channel Sales</h2></div>
                            <div class="header_section wrapper_search mt-4">
                              <div class="row">
                                <div class="col-md-8">
                                  <!-- <Datepicker class="dash_summary_form" v-model="channelDateRange" utc autoApply
                                    :range="true" :format="RangeCustomFormatter"
                                    @blur="searchReport(channelDateRange,'channelsales')">
                                  </Datepicker> -->

                                  <Datepicker  class="dash_summary_form"  v-model="channelDateRange" range :presetRanges="presetRanges" utc autoApply  :format="RangeCustomFormatter" 
                                    @blur="searchReport(channelDateRange,'channelsales')">
                                    <template #yearly="{ label, range, presetDateRange }">
                                      <span @click="presetDateRange(range)">{{ label }}</span>
                                    </template>
                                  </Datepicker>

                                </div>
                              </div>
                            </div>
                            <div v-if="channelSalesChartData.length > 1">
                              <GChart type="ColumnChart" :data="channelSalesChartData" :options="ChartOptions" />
                            </div>
                            <div v-else style="text-align:center;">
                              No Data
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <!--Left-->
                  <div class="col-md-4 pad-l-0 pl-s-15">

                    <div class="pl-4 pr-5 bd-b">
                      <div class="section_content pad-lr-0">
                        <div class="header_section wrapper_search">
                          <div class="dash_header_text"><h2>Store Sales</h2></div>
                          <div class="more-link">
                            <a href="#" class="orange_link" @click="redirectToDetailPage('report','salesByStore')">View Stores</a>
                          </div>
                        </div>
                        <div class="header_section wrapper_search">
                          <div class="row">
                            <div class="col-md-8">
                              <!-- <Datepicker class="dash_summary_form" v-model="storeDateRange" utc autoApply :range="true"
                                :format="RangeCustomFormatter" @blur="searchReport(storeDateRange,'storesales')">
                              </Datepicker> -->

                              <Datepicker  class="dash_summary_form"  v-model="storeDateRange" range :presetRanges="presetRanges" utc autoApply  :format="RangeCustomFormatter" 
                                @blur="searchReport(storeDateRange,'storesales')">
                                <template #yearly="{ label, range, presetDateRange }">
                                  <span @click="presetDateRange(range)">{{ label }}</span>
                                </template>
                              </Datepicker>
                            </div>
                          </div>
                        </div>
    
                        <div class="store_wrapper" v-for="store in storeSales" :key="store.name">
                          <div class="store_wrapper_content">
                            <div class="row" style="padding-bottom:15px;">
                              <!-- <div class="col-md-2">
                                <span v-if="store.image.length !=0 && store.image != null">
                                  <img style="border-radius:3px;vertical-align: middle;" width="48" height="48" :src="store.image" alt="">
                                </span>
                                <span v-else>
                                  <img style="border-radius:3px;vertical-align: middle;" width="48" height="48" src="../../../../public/img/no_images.jpg" alt="">
                                </span>
                              </div> -->
                              <div class="col-md-12">
                                <div class="">
                                  <span class="title_text">{{store.name}}</span> <br>
                                  <span class="small_desc_text">{{store.address}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4">
                                <span class="greay_header">TOTAL SALES</span> <br>
                                <span class="lg_sales_desc" style="color:#000000">{{formatPrice(store.total_amount)}}</span>
                              </div>
                              <div class="col-md-4">
                                <span class="greay_header">TOTAL ORDERS</span> <br>
                                <span class="lg_sales_desc" style="color:#383274">{{store.total_order}}</span>
                              </div>
                              <div class="col-md-4">
                                <span class="greay_header">CHANGE</span> <br>
                                <span v-if="store.change !='nochange'"
                                  :class="store.change == 'increase' ? 'increase_change lg_sales_desc' : 'decrease_change lg_sales_desc'">
                                  <i :class="store.change == 'increase' ? 'fa fa-arrow-up': 'fa fa-arrow-down'"
                                    style="font-size:12px"></i> &nbsp; {{store.percentange}}%
                                </span>
                                <span v-else>NA</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div><!-- Store sales -->
                    <div class="pl-4 pr-5">
                      <div class="section_content pad-lr-0">
                        <div class="header_section wrapper_search">
                          <div class="dash_header_text"><h2>Reviews</h2></div>
                          <div class="more-link">
                            <a href="#" class="orange_link" @click="redirectToDetailPage('review','')">View Reviews</a>
                          </div>
                        </div>
                        <div>
                          <div class="row">
                            <div class="col-md-12">
                              <button type="button" class="btn btn-purple btn-sm"> {{reviewData.new_reviews}} New</button>
                            </div>
    
                          </div>
                          <div class="review_section">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="customer_review">
                                  <div><span class="greay_header">CUSTOMER REVIEWS</span> </div>
                                  <div class="reviews_desc">
                                    <span class="reviews_desc_text">{{reviewData.customer_reviews}}</span>
                                  </div>
    
                                </div>
                                <div class="replies">
                                  <div><span class="greay_header">AWAITING REPLY</span></div>
                                  <div class="reviews_desc">
                                    <span class="reviews_desc_text">{{reviewData.unreplied_reviews}}</span>
                                  </div>
    
                                </div>
    
                              </div>
                              <div class="col-md-6">
                                <div class="avarage_ratings_box">
                                  <div class="review_ratings">{{reviewData.avarage_rating}}</div>
                                  <div class="stars_rates">
                                    <div class="rating">
                                      <ul class="list">
                                        <li :key="star" v-for="star in maxStars"
                                          :class="[{'five_star_rate star':star <= parseInt(reviewData.avarage_rating)},{'half': star == 4}]"
                                          class="star">
                                          <span class="far fa-star"></span>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  <div class="rating_desc">
                                    <span class="small_desc_text">Average Rating</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
    
                        </div>
    
                      </div>
                    </div><!-- review -->
                  </div>
                  <!--Right Col-->
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import { GChart } from 'vue-google-charts';
import Chart from 'chart.js'

import Multiselect from '@vueform/multiselect';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths } from 'date-fns';
import { ref } from 'vue';

export default {
  components: {
    GChart,
    Multiselect,
  },
  setup() {
        const date = ref();

        const presetRanges = ref([
          { label: 'Today', range: [new Date(), new Date()] },
          { label: 'This month', range: [startOfMonth(new Date()), endOfMonth(new Date())] },
          {
            label: 'Last month',
            range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
          },
          { label: 'This year', range: [startOfYear(new Date()), endOfYear(new Date())] },
          {
            label: 'This year (slot)',
            range: [startOfYear(new Date()), endOfYear(new Date())],
            slot: 'yearly',
          },
        ]);
        
        return {
          date,
          presetRanges,
        }
    },
  data() {
    return {
      export: '',
      spinner: false,
      summary: [],
      totalOrderSales: 0,
      totalPreviousOrderSales: 0,
      change: 'nochange',
      percentangeChange: '',
      reviewData: {},

      exportLoading: false,
      loading: true,
      startDate: this.$store.state.ordReportstartDate,
      endDate: this.$store.state.ordReportendDate,
      location: this.$store.state.ordReportlocation,
      stores: [],
      limit: 50,
      report: '',
      page: 1,
      totalPages: 0,
      catStores: '',
      itemStore: '',
      totalRecords: 0,
      recordsPerPage: 10,
      dateSalesData: [],
      lastPeriodChartData: [],
      orderType: 'sales',
      summarySalesChartData: [

      ],
      channelSalesChartData: [
        ['Channel', 'Sales']
      ],
      strsoptions: [{
        "value": '',
        "name": 'All Stores'
      }],
      ChartOptions: {
        height: 400,
        width: 400,
        bar: {groupWidth: "95%"},
        // legend: 'bottom',
        fontSize: 10
      },
      AgentOnlineChartData: [
        ['Online', 'Agent']
      ],
      productSales: [],
      customerSales: [],
      offerSummary:[],
      storeSales: [],
      agentSales: [],
      salesBtn: 'custon_orange',
      ordersBtn: 'btn-white',
      catBtnType: 'btn-grey',
      summaryTitle: 'Sales',
      channelSales: [],
      dateRange: [],
      storeDateRange: [],
      channelDateRange: [],
      itemsDataRange: [],
      maxStars: 5,
      currentPeriodTime: '',
      previousPeriodTime:''
    }
  },
  mounted: async function () {
    this.dateRange = [this.startDate, this.endDate];
    this.storeDateRange = [this.startDate, this.endDate];
    this.channelDateRange = [this.startDate, this.endDate];
    this.itemsDataRange = [this.startDate, this.endDate];
    this.getBranches();
    this.getSalesData(this.orderType);
    this.getReportSummary()
    this.getOffersData()
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/chance/1.0.18/chance.min.js')
    document.head.appendChild(recaptchaScript)
  },
  methods: {
    async exportOrder() {
      this.spinner = true;
      this.export = 1;
      try {
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        let url = `${this.dashBaseUrl}/orders-report-detail/${this.merchantID}`;
        url = new URL(url);
        url.searchParams.set('page', this.page);
        url.searchParams.set('size', this.recordsPerPage);
        if (this.startDate !== '') {
          url.searchParams.set('start_date', this.startDate);
        }
        if (this.endDate !== '') {
          url.searchParams.set('end_date', this.endDate);
        }
        if (this.location !=undefined && this.location !== '') {
          url.searchParams.set('store', this.location);
        }
        url.searchParams.set('is_export', 1);
        const colname = 'Name'
        const colvalue = 'Value'
        const response = await this.axios.get(url, config);
        if (response.status == 200) {
          this.spinner = false;
          let exportDAta = '';
          let fileName = '';
          fileName = Date.now() + '_productsales';
          exportDAta = response.data.data.productsales;


          const reportData = [];

          console.log('export data', exportDAta)

          for (const data of Object.values(exportDAta)) {
            const dataObj = {}
            dataObj[colname] = data.name;
            dataObj["Orders"] = data.total_order;
            if (data.change != undefined) {
              const type = data.change != 'nochange' ? data.change == 'increase' ? '+' : '-' : 'NA'
              dataObj['Change'] = data.percentange != undefined ? type + " " + data.percentange : ''
            }
            dataObj[colvalue] = data.currency + " " + this.formatPrice(data.total_amount)
            reportData.push(dataObj);
          }
          this.exportExcel(reportData, fileName)
          // AgentOnlineChartData
        }
      } catch (error) {
        this.spinner = false;
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }
      // 
    },
    redirectToDetailPage(reportType, report) {
      if (reportType == 'report') {
        this.$router.push({
          name: 'dashboard_details',
          params: {
            type: report
          }
        });
      } else if (reportType == 'review') {
        this.$router.push({
          name: 'reviews'
        })
      }

    },
    filterSalesSummary() {
      this.startDate = moment(this.dateRange[0]).format('YYYY-MM-DD');
      this.endDate = moment(this.dateRange[1]).format('YYYY-MM-DD');
      this.getSalesData(this.orderType);

      this.channelSalesChartData = [
        ['Channel', 'Sales']
      ];

      const payload = {
        'start_date': this.startDate,
        'end_date': this.endDate,
        'location': this.location,
      }
      this.$store.commit('updateOReportSearchData', payload)
    },
    filterReport() {
      this.channelSalesChartData = [
        ['Channel', 'Sales']
      ];
      this.startDate = moment(this.dateRange[0]).format('YYYY-MM-DD');
      this.endDate = moment(this.dateRange[1]).format('YYYY-MM-DD');
      this.getReportSummary();
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    RangeCustomFormatter(range) {
      const startDate = moment(range[0]).format('DD/MM/YYYY');
      const endDate = moment(range[1]).format('DD/MM/YYYY');
      return [startDate + " - " + endDate];
    },
    async getReportSummary() {
      this.loading = true;
      const LoaderCover = document.getElementById('main-loading-cover');
      LoaderCover.style.opacity = .4;
      const loader = document.getElementById('main-loader');
      loader.style.display = 'block';
      const payload = {
        'start_date': this.startDate,
        'end_date': this.endDate,
        'location': this.location,
      }
      this.$store.commit('updateOReportSearchData', payload)
      try {
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        let url = `${this.dashBaseUrl}/orders-report/${this.merchantID}`;
        url = new URL(url);
        if (this.startDate !== '') {
          url.searchParams.set('start_date', this.startDate);
        }
        if (this.endDate !== '') {
          url.searchParams.set('end_date', this.endDate);
        }
        if (this.location !=undefined && this.location !== '') {
          url.searchParams.set('store', this.location);
        }

        url.searchParams.set('limit', this.limit);
        const response = await this.axios.get(url, config);
        if (response.status == 200) {
          this.loading = false;
          this.summary = response.data.data;
          this.productSales = response.data.data.product_sales;
          this.storeSales = response.data.data.store_sales;
          const onlineSales = response.data.data.online_sales;
          this.agentSales = response.data.data.agent_sales;
          this.channelSales = response.data.data.channel_sales;
          this.customerSales = response.data.data.customer_sales;
          this.reviewData = response.data.data.review_data;

          // const date
          let agentSalesAmount = 0;
          for (const agentSale of Object.values(this.agentSales)) {
            agentSalesAmount = agentSalesAmount + parseFloat(agentSale.total_amount);
          }
          let onlineSalesAmount = 0;
          for (const onlineSale of Object.values(onlineSales)) {
            onlineSalesAmount = onlineSalesAmount + parseFloat(onlineSale.total_amount)
          }
          this.AgentOnlineChartData.push(
            ["Online Sales", onlineSalesAmount],
            ["Agent Sales", agentSalesAmount]
          )
          // construct chart for channels
          for (const channel of Object.values(this.channelSales)) {
            const channelsalesArr = [channel.name, channel.total_amount];
            this.channelSalesChartData.push(channelsalesArr)
          }
          // AgentOnlineChartData
          LoaderCover.style.opacity = 1;
          loader.style.display = 'none';
        }
      } catch (error) {
        this.loading = false;
        LoaderCover.style.opacity = 1;
        loader.style.display = 'none';
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }
    },
    async searchReport(range, type) {
      const startDate = moment(range[0]).format('YYYY-MM-DD');
      const endDate = moment(range[1]).format('YYYY-MM-DD');
      this.channelSalesChartData = [
        ['Channel', 'Sales']
      ];
      try {
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };

        let url = `${this.dashBaseUrl}/filter-order-report/${this.merchantID}`;
        url = new URL(url);
        url.searchParams.set('report_type', type);
        url.searchParams.set('start_date', startDate);
        url.searchParams.set('end_date', endDate);
        if (type == 'productsales' && this.itemStore !=undefined && this.itemStore != '') {
          url.searchParams.set('store', this.itemStore);
        }
        const response = await this.axios.get(url, config);
        if (response.status == 200) {
          switch (type) {
            case "productsales":
              this.productSales = response.data.data;
              break;
            case "storesales":
              this.storeSales = response.data.data;
              break;
            case "channelsales":
              for (const channel of Object.values(response.data.data)) {
                const channelsalesArr = [channel.name, channel.total_amount];
                this.channelSalesChartData.push(channelsalesArr)
              }
              break;
          }
          console.log('reponse data', response);
        }
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }
    },
    getDates(startDate, stopDate) {
      const dateArray = [];
      let currentDate = moment(startDate);
      const endDate = moment(stopDate);
      if (currentDate.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD')) {
        currentDate = moment(stopDate).subtract(7, 'd');
      }
      while (currentDate <= endDate) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
        currentDate = moment(currentDate).add(1, 'days');
      }
      return dateArray;
    },
    async getBranches() {
      const url = this.baseUrl + "/branch?merchant_id=" + this.merchantID;

      this.axios.get(url, this.apiRequestConfig()).then((response) => {

        if (response.status === 200) {
          if (response.data.data !== null) {
            const stores = response.data.data;
            this.stores = stores;
            for (let i = 0; i < stores.length; i++) {
              this.strsoptions.push({
                "value": stores[i].id,
                "name": stores[i].branch_name
              })
            }
          }
        }
      }).catch((err) => {
        console.log('error', err);
      })
    },
    async getOffersData(){
      // 
      const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
      };
      let url = `${this.dashBaseUrl}/offers_summary/${this.merchantID}`;
      url = new URL(url);
      url.searchParams.set('page', this.page);
      url.searchParams.set('size', this.recordsPerPage);
      if (this.startDate !== '') {
        url.searchParams.set('start_date', this.startDate);
      }
      if (this.endDate !== '') {
        url.searchParams.set('end_date', this.endDate);
      }
      if (this.location !=undefined && this.location !== '') {
        url.searchParams.set('store', this.location);
      }
      try{
        const response = await this.axios.get(url,config)
        if(response.status === 200){
          this.offerSummary = response.data.data.currentoffers;
        }
      }catch(err){
        // 
      }
    },
    async getSalesData(type = "sales") {
      this.orderType = type;
      try {
        const config = {
          headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
        };
        let url = `${this.dashBaseUrl}/sales-summary/${this.merchantID}`;
        url = new URL(url);
        url.searchParams.set('page', this.page);
        url.searchParams.set('size', this.recordsPerPage);
        if (this.startDate !== '') {
          url.searchParams.set('start_date', this.startDate);
        }
        if (this.endDate !== '') {
          url.searchParams.set('end_date', this.endDate);
        }
        if (this.location !=undefined && this.location !== '') {
          url.searchParams.set('store', this.location);
        }
        url.searchParams.set('limit', this.limit);
        const response = await this.axios.get(url, config);
        if (response.status == 200) {
          this.summaryTitle = (type == 'sales') ? "Sales" : "Order"
          if (type == 'sales') {
            this.salesBtn = ' custon_orange';
            this.ordersBtn = ' btn-white';
            this.totalOrderSales = response.data.data.current_sales;
            this.totalPreviousOrderSales = response.data.data.previous_sales;
            this.change = response.data.data.total_sale_change;
            this.percentangeChange = response.data.data.sales_percentage_change;
          } else {
            this.salesBtn = ' btn-white';
            this.ordersBtn = ' custon_orange';
            this.totalOrderSales = response.data.data.current_orders;
            this.totalPreviousOrderSales = response.data.data.previous_orders;
            this.change = response.data.data.total_orders_change;
            this.percentangeChange = response.data.data.orders_percentage_change;
          }
          const responseData = response.data.data.summary;

          const firstDataObj =  responseData[0]
          const lastDataObj = responseData[parseInt(responseData.length) - 1]

          if(firstDataObj.period_type == "month"){
            const cperionFirstDate = moment(firstDataObj.current_period_sales_date).format('MMMM, YYYY');
            const cperiodLastDate  = moment(lastDataObj.current_period_sales_date).format('MMMM, YYYY');
            const lperiodFirstDate =  moment(firstDataObj.previous_period_sales_date).format('MMMM, YYYY');
            const lperiodLastDate =  moment(lastDataObj.previous_period_sales_date).format('MMMM, YYYY');

            this.currentPeriodTime = `${cperionFirstDate} - ${cperiodLastDate}`
            this.previousPeriodTime  = `${lperiodFirstDate} - ${lperiodLastDate} `
          }else{
            const cperionFirstDate = moment(firstDataObj.current_period_sales_date).format('LL');
            const cperiodLastDate  = moment(lastDataObj.current_period_sales_date).format('LL');

            const lperiodFirstDate =  moment(firstDataObj.previous_period_sales_date).format('LL');
            const lperiodLastDate =  moment(lastDataObj.previous_period_sales_date).format('LL');
            if(firstDataObj.period_type == "hour"){
              this.currentPeriodTime = `${cperionFirstDate}`
              this.previousPeriodTime  = `${lperiodFirstDate} `
            }else{
              this.currentPeriodTime = `${cperionFirstDate} - ${cperiodLastDate}`
              this.previousPeriodTime  = `${lperiodFirstDate} - ${lperiodLastDate} `
            }
          }
          this.datesalesData = responseData;
          const headerLabel = [];
          const lastPeriodLabel = [];
          const currentPeriodLabel = [];
          const salesDataObjarr = []

          for (const resp of Object.values(responseData)) {
            salesDataObjarr.push(resp)
            if (resp.period_type == 'hour') {
              headerLabel.push(resp.date + ":00");
            } else {
              headerLabel.push(resp.date);
            }
            const orderType = (type == "sales") ? resp.sales_amount : resp.sales_order
            const previousOrderType = (type == "sales") ? resp.previous_amount : resp.previous_order
            currentPeriodLabel.push(orderType)
            lastPeriodLabel.push(previousOrderType)
          }

          this.summarySalesChartData = currentPeriodLabel,
          this.lastPeriodChartData = lastPeriodLabel

          
          let PeriodType = 'Date'
          switch(salesDataObjarr[0].period_type) {
            case "hour":
              PeriodType= 'Hour'
              break;
            case "month":
              PeriodType = "Month"
              break;
            default:
              PeriodType = "Date"
          }


          const options = {
            type: 'line',
            data: {
              labels: headerLabel,
              datasets: [{
                label: "Current Period",
                data: currentPeriodLabel,
                borderColor: '#383274',
                fill: false,
                pointBackgroundColor: 'white',
                borderWidth: 2
              }, {
                label: "Previous Period",
                data: lastPeriodLabel,
                borderColor: '#C04B2D',
                pointBackgroundColor: 'white',
                fill: false,
                borderDash: [10, 5],
                borderWidth: 2
              }]
            },
            options: {
              legend: {
                display: false
              },
              tooltips: {
                callbacks: {
                  title: function () {
                    return '';
                  },
                  label: function (t, d) {
                    const label = d.datasets[t.datasetIndex].label;
                    const value = d.datasets[t.datasetIndex].data[t.index];
                    const salesData = salesDataObjarr[t.index];
                    let date = '';
                    let change = '';
                    let perc = '';
                    if (type == 'sales') {
                      change = (salesData.sales_change == 'increase' && label != 'Previous Period') ? '+' : (salesData.sales_change == 'decrease' && label != 'Previous Period') ? '' : '';
                      perc = (salesData.sales_change != 'nochange' && label != 'Previous Period') ? salesData.sales_percentange + "%" : ''
                    } else {
                      change = (salesData.order_change == 'increase' && label != 'Previous Period') ? '+' : (salesData.order_change == 'decrease' && label != 'Previous Period') ? '' : '';
                      perc = (salesData.order_change != 'nochange' && label != 'Previous Period') ? salesData.order_percentange + "%" : ''
                    }

                    const dt = label == 'Previous Period' ? salesData.previous_period_sales_date : salesData.current_period_sales_date;
                    switch (salesData.period_type) {
                      case "hour":
                        date = salesData.date + ":00  " + moment(dt).format('LL');
                        break;
                      case "month":
                        date = moment(dt).format('MMMM, YYYY');
                        break;
                      case "day":
                        date = moment(dt).format('LL');
                        break;
                    }
                    const val = (value / 1).toFixed(2).replace(',', '.')
                    return `${label}:  ${date}   ${change}${perc}   ${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
                  }
                },
                backgroundColor: "#FAFAFA",
                borderColor: "#383274",
                borderWidth: 1,
                titleFontColor: "black",
                titleFontStyle: "bold",
                displayColors: false,
                bodyFontColor: "black"
              },
              scales: {
                  yAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: type.charAt(0).toUpperCase()+type.slice(1),       
                      },
                    }
                  ],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: PeriodType
                    }
                  }]
                }     
            }
          };
          document.getElementById("chart-container").innerHTML = '&nbsp;';
          document.getElementById("chart-container").innerHTML = '<canvas id="summary-chart"></canvas>';
          const ctx = document.getElementById('summary-chart').getContext('2d');
          new Chart(ctx, options);

        }
      } catch (error) {
        if (error.response.status === 401) {
          this.$router.push({
            name: 'login'
          });
        }
      }
      // 
    }


  },
  mixins: [ReusableDataMixins, ReusableFunctionMixins]
}
</script>
